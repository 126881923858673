import { registerSW } from "virtual:pwa-register";
import { dialogSW } from "@/composables/Modals";
import { useGlobalStore } from "@/stores/globalStore";

export function install() {
  const globalStore = useGlobalStore();

  if ('serviceWorker' in navigator) {
    registerSW({
      immediate: true,
      async onRegisteredSW(registrationPath) {
        const registration = await navigator.serviceWorker.register(registrationPath);
        console.log("✅ SW registrado");

        // Mantener activo el SW
        setInterval(() => {
          if (navigator.serviceWorker.controller) {
            navigator.serviceWorker.controller.postMessage({ type: "KEEP_ALIVE" });
          }
        }, 60000);

        // Detectar nueva versión
        registration.addEventListener('updatefound', () => {
          console.log("🚨 Actualización del SW detectada");
          dialogSW.value = true;
        });
      },

      onOfflineReady() {
        console.log("📴 Puede trabajar sin conexión");
        dialogSW.value = false;
      },

      onRegisterError(e) {
        console.error("❌ Error al registrar el SW", e);
        dialogSW.value = false;
      },
    });

    // Mensajes desde el SW
    navigator.serviceWorker.addEventListener('message', (event) => {
      switch (event.data?.type) {
        case 'ONLINE':
          console.log(`El navegador esta online: ${globalStore.isOnline}`)
          if (!globalStore.isOnline) {
            console.log('✅ Se confirmó la reconexión con el servidor');
            globalStore.isOnline = true;
          }
          break;

        case 'OFFLINE':
          if (globalStore.isOnline) {
            console.warn('🚫 El sistema detectó que no hay conexión');
            globalStore.isOnline = false;
          }
          break;

        case 'SW_INSTALLED':
          console.log('📦 Nuevo Service Worker instalado.');
          break;

        case 'SW_ACTIVATED':
          console.log('⚡ Service Worker activado.');
          break;

        case 'ALIVE_CONFIRMATION':
          console.log('🟢 SW sigue activo.');
          break;

        case 'ONLINE_CHECK':
          console.log('🔍 Verificación de conexión solicitada.');
          break;

        default:
          console.log('📩 Mensaje desde SW:', event.data);
      }
    });
  } else {
    console.log('⚠️ Service Workers no soportados en este navegador.');
  }
}

// https://thomashunter.name/posts/2021-12-11-detecting-if-pwa-twa-is-installed

const ua = navigator.userAgent;
const ios = ua.match(/iPhone|iPad|iPod/);
const standalone = window.matchMedia("(display-mode: standalone)").matches;
export const pwaInstance = reactive({ installed: false, needReload: false });

export function updatePWASW() {
  registerSW({
    immediate: true,

    async onNeedRefresh() {
      // dialogSW.value = true;
      console.log('onNeedRefresh');
      try {
        console.log('Service Worker necesita actualización');

        if ('serviceWorker' in navigator) {
          const registration = await navigator.serviceWorker?.getRegistration();
          if (registration?.active && registration?.waiting) {
            console.log('Service Worker activo y esperando');

            // Envía un mensaje al Service Worker activo para que salte la espera y se active
            registration.waiting.postMessage({ type: 'SKIP_WAITING' });

            // Limpia la caché para las URLs con parámetro de consulta
            caches.keys().then(cacheNames => {
              cacheNames.forEach(cacheName => {
                caches.delete(cacheName);
              });
            });

            // Actualiza la variable de estado pwaInstance.installed si es necesario
            pwaInstance.installed = !!(standalone || (ios && !ua.match(/Safari/)));

            // Llamada es necesaria para activar el nuevo Service Worker!
            await updateSW();
            console.log('Service Worker actualizado');

          } else {
            console.error('Service Worker no está soportado en este navegador');
          }
        }
      } catch (error) {
        console.error('Error al intentar actualizar el Service Worker', error);
      }
    },

    onRegisterError(e) {
      console.error('Error al registrar el Service Worker', e);
      // registrationError.value = true;
    },
  });
}
